import React from "react";

const EnvelopeSVG = ({ width, height, fill, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      {...props}
    >
      <path
        fill={fill}
        d="M19.28 2.21492C19.46 2.46492 19.56 2.77492 19.56 3.11492V12.8849C19.56 13.2049 19.46 13.5049 19.29 13.7549M19.28 2.21492C19 1.80492 18.53 1.54492 17.99 1.54492H2.57C2.04 1.54492 1.57 1.81492 1.28 2.21492M19.28 2.21492L12.7 7.97492M1.28 2.21492C1.1 2.46492 1 2.77492 1 3.11492V12.8849C1 13.2049 1.1 13.5049 1.27 13.7549C1.55 14.1749 2.03 14.4549 2.57 14.4549H18C18.54 14.4549 19.02 14.1749 19.3 13.7549L12.7 7.97492M1.28 2.21492L7.86 7.97492L1.26 13.7549M12.7 7.97492L11.4 9.11492C10.76 9.67492 9.81 9.67492 9.17 9.11492L7.87 7.97492"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default EnvelopeSVG;
