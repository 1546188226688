import React from "react";

const BuySplitShareSVG = ({ width, height, fill, stroke, style, ...props }) => {
  return (
    <div style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="311"
        height="40"
        fill="none"
        viewBox="0 0 311 40"
      >
        <path
          fill="#fff"
          d="M2.08 30V7.12h10.816c5.632 0 7.808 2.592 7.808 5.984v.128c0 2.656-1.408 4.32-3.968 4.928 2.688.48 4.576 2.144 4.576 5.344v.128c0 4.064-2.592 6.368-8.48 6.368H2.08zm7.072-4.768h2.304c1.856 0 2.72-.864 2.72-2.4v-.128c0-1.504-.832-2.336-2.88-2.336H9.152v4.864zm0-9.184h2.208c1.792 0 2.496-.608 2.496-2.016v-.128c0-1.376-.736-2.048-2.528-2.048H9.152v4.192zM29.07 30.416c-3.2 0-5.6-2.016-5.6-6.176V13.264h6.304v9.888c0 1.696.64 2.56 2.112 2.56 1.568 0 2.496-.928 2.496-2.912v-9.536h6.304V30h-6.304v-2.592c-.928 1.792-2.496 3.008-5.312 3.008zm17.245 5.152l2.72-7.232-6.688-15.072h6.816l3.169 8.352 2.944-8.352h5.951L52.3 35.568h-5.984z"
        ></path>
        <g filter="url(#filter0_d_667_3326)">
          <circle cx="86.5" cy="20" r="7.5" fill="#FFBF44"></circle>
          <circle
            cx="86.5"
            cy="20"
            r="6.5"
            stroke="#000"
            strokeWidth="2"
          ></circle>
        </g>
        <path
          fill="#fff"
          d="M121.688 30.416c-6.464 0-9.92-2.592-10.08-7.744h6.88c.128 1.44.832 2.624 3.2 2.624 1.696 0 2.528-.768 2.528-1.888 0-1.216-.8-1.6-3.264-1.952-6.592-.736-8.832-2.656-8.832-7.36 0-4.224 3.456-7.296 9.152-7.296 5.664 0 8.832 2.304 9.216 7.296h-6.72c-.16-1.408-.96-2.208-2.496-2.208-1.504 0-2.272.672-2.272 1.664 0 1.088.672 1.6 3.2 1.92 5.76.608 8.96 2.08 8.96 7.168 0 4.256-3.328 7.776-9.472 7.776zm11.722 5.152V13.264h6.304v2.432c.928-1.568 2.592-2.88 5.12-2.88 3.808 0 7.008 2.848 7.008 8.704v.256c0 5.856-3.232 8.64-7.04 8.64-2.464 0-4.256-1.184-5.088-2.816v7.968h-6.304zm9.088-9.952c1.888 0 2.944-1.344 2.944-3.904v-.256c0-2.688-1.088-3.968-2.976-3.968-1.856 0-2.912 1.408-2.912 3.968v.256c0 2.592 1.024 3.904 2.944 3.904zM154.258 30V6h6.272v24h-6.272zm9.626 0V13.264h6.272V30h-6.272zm3.136-17.984c-1.952 0-3.424-1.344-3.424-3.168 0-1.856 1.472-3.2 3.424-3.2 1.92 0 3.391 1.344 3.391 3.2 0 1.824-1.471 3.168-3.391 3.168zm13.335 18.368c-3.936 0-6.176-1.792-6.176-5.984v-6.912h-1.952v-4.224h1.952V9.84h6.304v3.424h3.2v4.224h-3.2v6.304c0 1.152.608 1.632 1.632 1.632a4.36 4.36 0 001.568-.256v4.736c-.768.224-1.952.48-3.328.48z"
        ></path>
        <g filter="url(#filter1_d_667_3326)">
          <circle cx="209.5" cy="20" r="7.5" fill="#FFBF44"></circle>
          <circle
            cx="209.5"
            cy="20"
            r="6.5"
            stroke="#000"
            strokeWidth="2"
          ></circle>
        </g>
        <path
          fill="#fff"
          d="M244.688 30.416c-6.464 0-9.92-2.592-10.08-7.744h6.88c.128 1.44.832 2.624 3.2 2.624 1.696 0 2.528-.768 2.528-1.888 0-1.216-.8-1.6-3.264-1.952-6.592-.736-8.832-2.656-8.832-7.36 0-4.224 3.456-7.296 9.152-7.296 5.664 0 8.832 2.304 9.216 7.296h-6.72c-.16-1.408-.96-2.208-2.496-2.208-1.504 0-2.272.672-2.272 1.664 0 1.088.672 1.6 3.2 1.92 5.76.608 8.96 2.08 8.96 7.168 0 4.256-3.328 7.776-9.472 7.776zm16.554 0c-3.008 0-5.696-1.472-5.696-5.056 0-3.936 3.2-5.472 8.992-5.472h1.44V19.6c0-1.408-.256-2.432-1.952-2.432-1.44 0-1.888.928-1.984 1.792h-5.888c.256-4.096 3.456-6.176 8.256-6.176 4.864 0 7.808 1.984 7.808 6.4V30h-6.08v-1.92c-.704 1.152-2.176 2.336-4.896 2.336zm2.272-4.224c1.44 0 2.464-.8 2.464-2.048v-.864h-1.344c-2.112 0-2.88.416-2.88 1.6 0 .8.608 1.312 1.76 1.312zM279.46 30l-6.144-16.736h6.72l3.168 10.176 3.2-10.176h5.92L286.212 30h-6.752zm21.992.416c-5.344 0-9.28-3.04-9.28-8.576v-.256c0-5.44 4.16-8.8 9.28-8.8 4.704 0 8.704 2.688 8.704 8.704v1.536H298.54c.16 2.016 1.344 3.104 3.136 3.104 1.76 0 2.432-.832 2.624-1.792h5.888c-.512 3.84-3.424 6.08-8.736 6.08zm-2.88-10.688h5.504c-.064-1.792-.928-2.88-2.624-2.88-1.536 0-2.624.992-2.88 2.88z"
        ></path>
        <defs>
          <filter
            id="filter0_d_667_3326"
            width="16"
            height="16"
            x="79"
            y="12.5"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dx="1" dy="1"></feOffset>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_667_3326"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_667_3326"
              result="shape"
            ></feBlend>
          </filter>
          <filter
            id="filter1_d_667_3326"
            width="16"
            height="16"
            x="202"
            y="12.5"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset dx="1" dy="1"></feOffset>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_667_3326"
            ></feBlend>
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_667_3326"
              result="shape"
            ></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default BuySplitShareSVG;
