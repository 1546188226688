import React from "react";

const LocationSVG = ({ width, height, fill, stroke, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width} //26
      height={height} //23
      fill="none"
      viewBox="0 0 14 19"
    >
      <path
        stroke={stroke}
        d="M6.6 17L6.22895 17.3351L6.6 17.746L6.97105 17.3351L6.6 17ZM6.6 17C6.97105 17.3351 6.97113 17.3351 6.97123 17.3349L6.97153 17.3346L6.97252 17.3335L6.976 17.3296L6.98892 17.3152L7.03764 17.2604C7.07994 17.2125 7.14145 17.1423 7.21943 17.0517C7.37536 16.8708 7.59729 16.6084 7.86323 16.2807C8.39467 15.6259 9.10408 14.7076 9.81448 13.6547C10.5239 12.6032 11.2405 11.4084 11.7813 10.2013C12.3191 9.00078 12.7 7.75117 12.7 6.6C12.7 4.98218 12.0573 3.43062 10.9134 2.28665C9.76938 1.14268 8.21782 0.5 6.6 0.5C4.98218 0.5 3.43062 1.14268 2.28665 2.28665C1.14268 3.43062 0.5 4.98218 0.5 6.6C0.5 7.75117 0.880864 9.00078 1.4187 10.2013C1.95947 11.4084 2.67608 12.6032 3.38552 13.6547C4.09592 14.7076 4.80533 15.6259 5.33677 16.2807C5.60271 16.6084 5.82464 16.8708 5.98057 17.0517C6.05855 17.1423 6.12006 17.2125 6.16236 17.2604L6.21108 17.3152L6.224 17.3296L6.22748 17.3335L6.22847 17.3346L6.22877 17.3349C6.22887 17.3351 6.22895 17.3351 6.6 17ZM7.98582 7.17403L8.44776 7.36537L7.98582 7.17403C7.91044 7.35601 7.79995 7.52137 7.66066 7.66066C7.52137 7.79995 7.35601 7.91044 7.17403 7.98582C6.99204 8.0612 6.79698 8.1 6.6 8.1C6.20218 8.1 5.82064 7.94197 5.53934 7.66066C5.25804 7.37936 5.1 6.99783 5.1 6.6C5.1 6.20218 5.25804 5.82064 5.53934 5.53934C5.82064 5.25804 6.20218 5.1 6.6 5.1C6.99782 5.1 7.37935 5.25804 7.66066 5.53934C7.94196 5.82064 8.1 6.20218 8.1 6.6C8.1 6.79698 8.0612 6.99204 7.98582 7.17403Z"
      ></path>
    </svg>
  );
};

export default LocationSVG;
