import React from "react";

const PhoneSVG = ({ width, height, fill, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      {...props}
    >
      <path
        fill={fill}
        d="M6.28542 14.7373C11.2854 19.8373 14.3054 20.1673 15.5554 19.9873C16.5054 19.8473 18.1454 18.3173 18.7954 17.5073C18.9754 17.2873 19.0654 17.0073 19.0554 16.7173C19.0454 16.3773 18.8954 16.0573 18.6654 15.8173L16.1654 13.3173C15.9954 13.1473 15.7654 13.0373 15.5254 13.0273H15.4554C15.1054 12.9973 14.7554 13.1273 14.5054 13.3773L12.8854 14.9973C12.5954 15.2873 12.3154 15.2873 11.7954 15.1173C11.7954 15.1173 10.6254 14.8473 8.40542 12.6273C6.18542 10.4073 5.91542 9.23731 5.91542 9.23731C5.74542 8.71731 5.74542 8.43731 6.03542 8.14731L7.65542 6.52731C7.90542 6.27731 8.03542 5.92731 8.00542 5.57731V5.50731C7.98542 5.26731 7.87542 5.03731 7.70542 4.86731L5.20542 2.36731C4.96542 2.12731 4.64542 1.98731 4.30542 1.97731C4.02542 1.96731 3.74542 2.05731 3.51542 2.23731C2.70542 2.88731 1.17542 4.52731 1.03542 5.47731C0.855417 6.72731 1.18542 9.75731 6.28542 14.7473V14.7373Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneSVG;
